import React, {useState} from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import StripeForm from "../StripeForm/StripeForms";
import {Input} from "antd";
import styles from "./PointsInformation.module.css";
const PointsInformation = ({ points }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [pointsBuy, setPointsBuy] = useState(1); 
    const onClose = () => {
        setIsOpened(false);
    };

    const handleClick = () => {
        setIsOpened(true);
    };
    const handlePointsChange = (e) => {
        const value = parseInt(e.target.value, 10); // 获取输入框中的值
        if (value > 0) {
            setPointsBuy(value);
        }
    };
    return (
        <div className={styles.cardContainer}>
        <div className={styles.pointsCard}>
            <div className={styles.pointsContent}>
                <h2 className={styles.pointsHead}>Points</h2>
                <div className={styles.HeadingAndNumber}>
                    <h3 className={styles.pointsHeading}>Remaining Points</h3>
                    <div className={styles.pointsNumberContainer}>
                        <img src="/coins-stacked-01.svg" alt="Coins Stacked" className={styles.iconCoins} />
                        <h1 className={styles.pointsNumber}>{points}</h1>
                    </div>
                </div>
            </div>
            <div className={styles.pointsButtonContainer}>
                <Button className={styles.pointsButton} onClick={handleClick}>
                    <p className={styles.buttonTxt}>Points recharge</p>
                </Button>
            </div>
        </div>
        <div className={styles.informationContainer}>
            <div className={styles.informationContent}>
                <div className={styles.groupSeven}>
                    <div className={styles.FAQitem}>
                        <div className={styles.FAQContent}>
                            <div className={styles.IconWrap}>
                                <img src="/help-circle.svg" alt="help icons"/>
                            </div>
                            <div className={styles.supportingText}>
                                <span className={styles.supportingTextTitle}>Points recharge rules</span>
                                <span className={styles.supportingTextPargraph}>Earn 1 point for every 1 USD recharged.
                                    {/* <br />Receive a 
                                    <span className={styles.discount}>
                                    &nbsp;5% discount&nbsp;
                                    </span> 
                                    for every 1000 USD recharged. */}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* <div className={styles.group23}>
                        <span className={styles.viewTxt}>
                            View
                        </span>
                    </div> */}
                </div>
            </div>
        </div>

       {isOpened && (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.pointsContainer}>
                    <button className={styles.closeButton} onClick={onClose}>
                        <CloseOutlined style={{ fontSize: '20px', color: '#98A2B3' }} />
                    </button>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>Recharge</div>
                        <div className={styles.subtitle}>You can recharge the points here</div>
                    </div>
                    <p className={styles.pointsInfo}> Recharge Amount</p>
                    <Input 
                        type="number" 
                        value={pointsBuy} 
                        onChange={handlePointsChange} 
                        min="1" 
                        placeholder="Enter points"
                        prefix="$"
                    />
                </div>
                <StripeForm points={pointsBuy}/>
            </div>
        </div>
       )}
    </div>
    );
};

export default PointsInformation;