import React,{ useState,useEffect } from "react";
import axios from "axios";
import config from "../../config/config";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/SideBar/Sidebar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import PointsInformation from "../../Components/PointsInformation/PointsInformation";
import  Invoicelist from "../../Components/InvoiceList/Invoicelist";
import { useSelector } from 'react-redux';
import InvoiceFileSelecter from "../../Components/InvoiceFileSelecter/InvoiceFileSelecter";
import FileGrid from "../../Components/FileGrid/FileGrid";
import { Spin } from 'antd';
import styles from "./PointsRechatgePage.module.css";

const PointsRechargePage = () => {
    const cur_page = '/points';
    const title = 'Ponints Recharge';
    const subtitle = 'Here you can manage all your points.';
    const buttontxt = 'Add Candidates';
    const [totalCredits, setTotalCredits] = useState(0);
    const [initialInvoices, setInitialInvoices] = useState([]);
    const id = useSelector((state) => state.auth.companyId);

    // 用于发票列表加载状态
    const [initialValues, setInitialValues] = useState({});
    const [invoiceLoading, setInvoiceLoading] = useState(true);
    const [cur_selected, setCurSelected] = useState("invoice");

    
    const fetchCompanyData = async () => {
        try {
          const response = await axios.post(`${config.BACKEND_ENDPOINT}/company/getCompanyById`, {
            company_id: id
          });
          setInitialValues(response.data);

          setTotalCredits(response.data.cur_credits + response.data.gift_credits);

          const response2 = await axios.post(`${config.BACKEND_ENDPOINT}/invoice/search`, {
            invoice_id: response.data.most_recent_invoice
          });
          setInitialInvoices(response2.data.info);
          setTotalCredits(response.data.cur_credits + response.data.gift_credits);
          setInvoiceLoading(false);
        } catch (error) {
          console.error('Error fetching company data:', error);
          setInvoiceLoading(false);
        }
      };
    
      useEffect(() => {
        fetchCompanyData();
      }, []);

    return (
        <div className={styles.overall}>
        <Header />
            <div className={styles.container}>
                <Sidebar cur_page = {cur_page}/>
                <div className={styles.content}>
                    <PageTitle title={title} subtitle={subtitle} buttontxt={buttontxt}/>
                    <PointsInformation points={totalCredits}/>

                    <div className={styles.projectManagement}>
                        <div className={styles.projectListHeader}>
                            <h3 className={styles.projectListTitle}>Invoice Records</h3>
                            <p className={styles.viewAllLink}>You can view transaction points records here. </p>
                        </div>
                    </div>
                    <InvoiceFileSelecter cur_selected={cur_selected} setCurSelected={setCurSelected} />
                    {cur_selected === "invoice" ? (
                      invoiceLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '150px' }}>
                          <Spin tip="Loading invoices..." size="large"/>
                        </div>
                      ) : (
                        <Invoicelist invoices={initialInvoices} />
                      )
                    ) : (
                      <FileGrid company={initialValues} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PointsRechargePage;