import React from "react";
import { Radio } from 'antd';
import styles from './InvoiceFileSelecter.module.css'
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const InvoiceFileSelecter = ({cur_selected, setCurSelected,id}) => {
    return (
        <div className={styles.projectManagement}>
            <RadioGroup value={cur_selected} onChange={(e) => setCurSelected(e.target.value)} size="large" className={styles.textContent}>
                <RadioButton value="invoice">Invoice Record</RadioButton>
                <RadioButton value="file">Attachment</RadioButton>
            </RadioGroup>
        </div>
    )
}

export default InvoiceFileSelecter;