import React, { useState } from 'react';
import { Upload, message, Modal, Pagination, Button, Row, Spin } from 'antd';
import { InboxOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from "../../config/config";
import { v4 as uuidv4 } from 'uuid';

const { Dragger } = Upload;

const FileGrid = ({ company }) => {
  const [files, setFiles] = useState(() => {
    if (company && company.files) {
      return company.files.map((file, index) => ({
        id: String(index + 1),
        name: file.name,
        url: file.url
      }));
    }
    return [];
  });

  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const currentFiles = files.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // 拦截上传，将文件加入 pendingFiles 状态
  const beforeUpload = (file) => {
    setPendingFiles(prev => [...prev, file]);
    return false;
  };

  const handleRemove = (file) => {
    setPendingFiles(prev => prev.filter(item => item.uid !== file.uid));
  };

  const handleManualUpload = async () => {
    if (pendingFiles.length === 0) {
      message.warning('Please select file(s) before uploading');
      return;
    }

    // 开始上传前设置 loading 状态
    setUploading(true);

    const formData = new FormData();
    const company_info = {
      company_id: company.company_id,
      company_name: company.company_name
    };
    formData.append('company_info', JSON.stringify(company_info));
    pendingFiles.forEach(file => {
      formData.append('files', file);
    });
    
    try {
      const response = await axios.post(`${config.BACKEND_ENDPOINT}/company/uploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      message.success('Files uploaded successfully!');
      setFiles(prevFiles => [
        ...response.data.url.map(file => ({
          ...file,
          id: uuidv4()
        })),
        ...prevFiles
      ]);
      setPendingFiles([]);
    } catch (error) {
      console.error('Upload error:', error);
      message.error('Upload failed!');
    } finally {
      // 上传结束后取消 loading 状态
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload,
    onRemove: handleRemove,
    fileList: pendingFiles,
  };

  const getFileViewerUrl = (url) => {
    if (url.endsWith('.pdf')) {
      return `https://docs.google.com/gview?url=${url}&embedded=true`; // PDF 使用 gview 预览
    } else if (url.endsWith('.doc') || url.endsWith('.docx')) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`; // Office 文档预览
    }
    return url;
  };

  return (
    <div style={{ padding: 20, position: 'relative' }}>
      {/* 上传过程中显示遮罩和 Spin */}
      {uploading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Spin size="large" tip="Uploading files..." />
        </div>
      )}

      {/* 上传区域 */}
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to select files
        </p>
        <p className="ant-upload-hint">
          Support single or multiple file selection. Files will be uploaded only when you click the "Upload" button.
        </p>
      </Dragger>

      {pendingFiles.length > 0 && (
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={handleManualUpload}
          style={{ marginTop: 16 }}
        >
          Upload
        </Button>
      )}

      {/* 文件列表 */}
      <div style={{ marginTop: 50 }}>
        <Row gutter={[16, 16]}>
          {currentFiles.map((file) => (
            <div
              key={file.id}
              style={{
                width: '10%',
                textAlign: 'center',
                cursor: 'pointer'
              }}
              onClick={() => {
                setSelectedFile(file);
                setModalVisible(true);
              }}
            >
              <div>
                <FileOutlined style={{ fontSize: 48, marginBottom: 8 }} />
                <div style={{ marginTop: 3 }}>
                  {file.name.length > 20 ? file.name.slice(0, 10) + '...' : file.name}
                </div>
              </div>
            </div>
          ))}
        </Row>

        {files.length > itemsPerPage && (
          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={files.length}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

      <Modal
        title="File Preview"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>
            Close
          </Button>
        ]}
        width="50%"
      >
        {selectedFile && (
          <iframe
            src={getFileViewerUrl(selectedFile.url)}
            style={{ width: '100%', height: '600px' }}
            frameBorder="0"
            title={selectedFile.name}
          />
        )}
      </Modal>
    </div>
  );
};

export default FileGrid;